import React, {Component} from "react"

abstract class StateSavingComponent extends Component<any> {
    static UPDATE_KEY: any = "__UPDATE_STATE__"
    visibleStates: Set<string> = new Set();
    states: any = {} 
    state: any = {}

    constructor(props: any) {
        super(props);
        this.state[StateSavingComponent.UPDATE_KEY] = null
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.ChangeEvent<any>) {
        const {name, value} = event.target
        this.renderVisibleStates(name, value)
        this.states[name] = value
    }

    getState(key: string) {
        return this.states[key]
    }

    assignState(key: string, value: any) {
        this.renderVisibleStates(key, value)
        return this.states[key] = value
    }

    private renderVisibleStates(key: string, value: any) {
        if (this.visibleStates.has(key)) {
            this.setState({[StateSavingComponent.UPDATE_KEY]: value})
        }
    }
}

export default StateSavingComponent 
