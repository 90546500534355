import {Component} from "react"

class Footer extends Component<any> {
    render() {
        return (
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="footer-item">
                        <div className="footer-header">Contact Us</div>
                        <div className="footer-info" id="contact-us">
                            Feedback or general queries are welcomed, contact us at jakemcnichollarchitect@gmail.com
                        </div>
                    </div>
                    <div className="footer-item">
                        <div className="footer-header">
                            Usage Instructions
                        </div>
                        <div className="footer-info" id="usage-guidance">
                            Enter your address, click valuate and we will provide you with a valuation estimate for your home.
                            <br></br>
                            <br></br>
                            Our valuations work best for houses we are working on extending the website to provide valuation estimates for apartments too.
                            Valuation are currently only available in the Republic of Ireland.
                        </div>
                    </div>
                    <div className="footer-item">
                        <div className="footer-header">Disclaimer</div>
                        <div className="footer-info" id="disclaimer">
                            The house valuation estimates provided are for informational purposes only and should not be considered as financial or professional advice.
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
