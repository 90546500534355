import {Component} from "react"


class ValuationDescription extends Component<any> {
    render() {
        return (
            <div className="valuation-description"> 
                <svg width="100%" height="100%" className="website-name">
                    <text x="50%" y="35%" dominantBaseline="middle" fontSize="12" textAnchor="middle" className="major-mono-display-regular">
                        ireland's house Valuation tool
                    </text>
                    <text x="50%" y="65%" dominantBaseline="middle" fontSize="12" textAnchor="middle" className="major-mono-display-regular">
                        Valuate your home
                    </text>
                </svg>
            </div>
        )
    }
}

export default ValuationDescription
