import {Component} from "react"
import SpecialTitle from "../custom/SpecialTitle"


class WebsiteName extends Component<any> {
    render() {
        return (
            <SpecialTitle className="website-name-container website-name">
                Valterrain.com
            </SpecialTitle>
        )
    }
}

export default WebsiteName
