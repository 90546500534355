import ValuatingComponent from "src/components/base/ValuatingComponent";
import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";

class ValuatingChildComponent extends StateSavingChildComponent {
    parent: ValuatingComponent;

    constructor(props: any) {
        super(props);
        this.parent = props.parent
        this.valuate = this.valuate.bind(this);
        this.newValuation = this.newValuation.bind(this);
    }

    async valuate() {
        return this.parent.valuate()
    }

    newValuation() {
        return this.parent.newValuation()
    }
}

export default ValuatingChildComponent