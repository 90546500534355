import StateSavingComponent from "src/components/base/StateSavingComponent"

class InfoButton extends StateSavingComponent {

    constructor(props: any) {
        super(props)
        this.visibleStates = new Set([
            "popUp"
        ])
        this.states = {
            "popUp": false,
        }
        this.render = this.render.bind(this)
        this.showInfo = this.showInfo.bind(this)
        this.removeInfo = this.removeInfo.bind(this)
        this.renderPopUp = this.renderPopUp.bind(this)
    }

    render() {
        return (
            <div>
                {this.getState("popUp") && this.renderPopUp()}
                <button id="info-button" className="info-button" onClick={this.showInfo}>ⓘ</button>
            </div>
       )
    }

    renderPopUp() {
        return (
            <div className="information-pop-up-background">
                <div id="information-pop-up-container" className="information-pop-up-container">
                    {this.props.children}
                    <button id="remove-info-button" className="x-button remove-info-button" onClick={this.removeInfo}>X</button>
                </div>
            </div>
        )
    }

    showInfo() {
        this.assignState("popUp", true)
        console.log("assigning state")
    }

    removeInfo() {
        this.assignState("popUp", false)
        console.log("removing state")
    }
}

export default InfoButton
