import ValuatingChildComponent from "src/components/base/ValuatingChildComponent";
import AddressField from "src/components/house_valuation/house_valuation_inputs/forms/AddressField";


class MainForm extends ValuatingChildComponent {
    render() {
        return (
            <fieldset className="main-form container">
                <AddressField className="partition" parent={this.parent}/>
            </fieldset>
        )
    }
}

export default MainForm
