import ValuatingChildComponent from "src/components/base/ValuatingChildComponent";
import ToggleButton from "src/components/custom/ToggleButton"



class AddressField extends ValuatingChildComponent{

    static SUPPORTED_COUNTRIES_AND_COUNTIES : any = {
        "Ireland": [
                "Carlow",
                "Cavan",
                "Clare",
                "Cork",
                "Donegal",
                "Dublin",
                "Galway",
                "Kerry",
                "Kildare",
                "Kilkenny",
                "Laois",
                "Leitrim",
                "Limerick",
                "Longford",
                "Louth",
                "Mayo",
                "Meath",
                "Monaghan",
                "Offaly",
                "Roscommon",
                "Sligo",
                "Tipperary",
                "Waterford",
                "Westmeath",
                "Wexford",
                "Wicklow"
        ]
    }

    constructor(props: any) {
        super(props)
        this.renderCountyOptions = this.renderCountyOptions.bind(this);
    }

    renderCountryOptions() {
        let options : any = []
        for (let country of Object.keys(AddressField.SUPPORTED_COUNTRIES_AND_COUNTIES)) {
            let id = country + "-address-country-option"
            options.push((<option id={id} value={country}>{country}</option>))
        }
        return options
    }
    
    renderCountyOptions() {
        let options : any = []
        let country : any = this.getState("country")
        if (!(country in AddressField.SUPPORTED_COUNTRIES_AND_COUNTIES)) {
            return options
        }
        let counties : any = AddressField.SUPPORTED_COUNTRIES_AND_COUNTIES[country]
        for (let county of counties) {
            let id = county + "-address-county-option"
            options.push((<option id={id} value={county}>{county}</option>))
        }
        return options
    }

    render() {
        return (
            <div className="address-field">
                <div>
                    <select className="field county-field" id="valuation-form-country-field" defaultValue={this.getState("country")} name="country" onChange={this.handleChange}>
                        <option value="">Country</option>
                        {this.renderCountryOptions()}
                    </select>
                    <select className="field country-field" id="valuation-form-county-field" defaultValue={this.getState("county")} name="county" onChange={this.handleChange}>
                        <option value="">County</option>
                        {this.renderCountyOptions()}
                    </select>
                    <input type="text" className="field town-field" placeholder="Town" id="valuation-form-town" name="town" defaultValue={this.getState("town")} onChange={this.handleChange}></input>
                </div>
                <div>
                    <input type="text" className="field street-field" placeholder="🔎︎ Street Address" id="valuation-form-street-address" name="street-address" defaultValue={this.getState("street-address")} onChange={this.handleChange}></input>
                    <button className="partition field valuation-button" onClick={this.valuate}>Valuate</button>
                    <ToggleButton className="partition toggle" parent={this.parent} toggleField={"advancedOptions"}/>
                </div>
            </div>
        )
    }
}

export default AddressField
