import {Component, ReactNode} from "react"

class SpecialTitle extends Component<any> {
    className: any

    constructor(props: any) {
        super(props)
        this.className = props.className
    }

    render() {
        let className = "special-title " + this.className

        return (
            <svg width="100%" height="100%" className={className}>
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="major-mono-display-regular">
                    {this.props.children}
                </text>
            </svg>
        )
    }
}

export default SpecialTitle
