import {Component} from "react"
import InfoButton from "src/components/custom/InfoButton"


class ValuationInfoButton extends Component<any> {

    render() {
        /*
        TODO add the condfidence score once it is implemented.

        <div className="info-section">
            <div className="info-header">
                Confidence Score
            </div>
            The confidence score is a measure of the degree of confidence in the valution estimate range provided. The confidence score is impacted by factors such as the number of nearby homes identified, the proximatey of these houses to the property being valuated and the recency of the sales.
        </div>
        */

        return (
            <InfoButton>
                <div className="valuation-information">
                    <div className="info-section">
                        <div className="info-header">
                            Valuations
                        </div>
                        Our valution estimates are based on prices of nearby houses that sold in the area adjusted to account for current housing market conditions.
                        The set of nearby homes used for the basis of the valuation are listed in the comparable homes section.
                    </div>
                    <div className="info-section">
                        <div className="info-header">
                            Comparable Homes
                        </div>
                        A list of nearby homes in the area, that are used as the basis for the valuation estimate.
                        Feel free to remove any houses that may be skewing the valution and update your valution to see the results.
                    </div>
                    <div className="info-section">
                        <div className="info-header">
                            House Condition
                        </div>
                        In the advanced options section you have the option to set the house condition. 
                        The house condition is your estimate of the condition of the house relative to the nearby houses identified in the comparable homes section.
                        <br></br>
                        <br></br>
                        0%: Significantly worse than the other houses
                        <br></br>
                        <br></br>
                        25%: Needs some work
                        <br></br>
                        <br></br>
                        50%: Middle of the pack
                        <br></br>
                        <br></br>
                        75%: You're onto something good
                        <br></br>
                        <br></br>
                        100%: On a different level
                    </div>
                    <br></br>
                    <br></br>
                </div>
            </InfoButton>
        )
    }

}

export default ValuationInfoButton
