import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import ComparableHomesWidget from "src/components/house_valuation/house_valuation_results/widgets/ComparableHomesWidget";
import ValuationResultWidget from "src/components/house_valuation/house_valuation_results/widgets/ValuationResultWidget";
import UpdateValuationForm from "./forms/UpdateValuationForm";
import ValuationInfoButton from "./widgets/ValuationInfoButton";
import SpecialTitle from "src/components/custom/SpecialTitle";


class HouseValuationResultsPage extends StateSavingChildComponent {
    render() {
        let streetAddress: string = this.getState("street-address")
        let town: string = this.getState("town")
        let propertyAddress: string = streetAddress + ", " + town

        return (
            <div>
                <UpdateValuationForm parent={this.parent}/>
                <SpecialTitle className="property-title">{propertyAddress.toLowerCase()}</SpecialTitle>
                <div id="valuation-container" className="valuation-container">
                    <div className="valuation-visual-partition">
                        <div className="info-title-container">
                            <SpecialTitle className="valuation-title">Valuation</SpecialTitle>
                            <ValuationInfoButton/>
                        </div>
                        <ValuationResultWidget parent={this.parent}/>
                    </div>
                    <div className="valuation-data-partition">
                        <div id="comparable-homes-container" className="comparable-homes-container">
                            <div className="info-title-container">
                                <SpecialTitle className="comparable-homes-title">comparable homes</SpecialTitle>
                                <ValuationInfoButton/>
                            </div>
                            <ComparableHomesWidget parent={this.parent}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HouseValuationResultsPage
