import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";

class ToggleButton extends StateSavingChildComponent {
    toggleField : string
    spacing: number = 25
    id: string

    constructor(props: any) {
        super(props)
        this.parent = props.parent
        this.toggleField = props.toggleField
        this.toggleOptions = this.toggleOptions.bind(this)
        this.id = this.toggleField + "-toggle-button"
    }

    render() {
        return (
            <button id={this.id} className="toggle-button" onClick={this.toggleOptions}>{this.getToggleIcon()}</button>
        )
    }

    getToggleIcon() {
        return (
            <svg className="toggle-icon">
                {this.getArrow(73 - this.spacing / 2)}
                {this.getArrow(50 - this.spacing / 2)}
                {this.getArrow(27 - this.spacing / 2)}
            </svg>
        )
    }

    getArrow(yPercentLocation: number) {
        let optionEnabled = this.getState(this.toggleField)

        let ySide = (yPercentLocation + this.spacing) + "%"
        let yCenter = (yPercentLocation) + "%"
        if (optionEnabled) {
            yCenter = (yPercentLocation + this.spacing) + "%"
            ySide = (yPercentLocation) + "%"
        }

        /*
        let ySide = (yPercentLocation) + "%"
        let yCenter = (yPercentLocation) + "%"
        if (optionEnabled) {
            yCenter = (yPercentLocation + this.spacing) + "%"
            //let ySide = (yPercentLocation - this.spacing) + "%"
        }
        */

        return (
            <svg className="toggle-icon">
                <line className="toggle-icon-upstroke" x1="25%" y1={ySide} x2="50%" y2={yCenter}/>
                <line className="toggle-icon-downstroke" x1="50%" y1={yCenter} x2="75%" y2={ySide}/>
            </svg>
        )
    }

    toggleOptions() {
        let options = this.getState(this.toggleField)
        this.assignState(this.toggleField, !options)
    }
}

export default ToggleButton